/* eslint-disable no-undef */
/* eslint-disable func-names */
import * as Yup from "yup"
import { addDays, isAfter, isBefore, isEqual, isValid } from "date-fns"
import {
  toJPformatter,
  getCurrencyFormattWithoutSymbol,
  isPayByCrypto,
  isPayByEther,
  isPayByMatic,
} from "./helper"
import { PAYMENT_METHOD_MASTER } from "../components/Purchase/PaymentMethod/PaymentMethod"

const AUCTION_MAX_OFFER_PRICE = 1000000000 // 10億
/**
 * カスタムバリデーション
 * ひらがな入力
 */
Yup.addMethod(Yup.string, "hiragana", function () {
  return this.test("hiragana", "ひらがなで入力して下さい", (value) => {
    if (value == null || value === "") return true
    return value.match(/^[ぁ-んー\s]+$/)
  })
})
/**
 * カスタムバリデーション
 * カタカナ入力
 */
Yup.addMethod(Yup.string, "katakana", function () {
  return this.test("katakana", "カタカナで入力して下さい", (value) => {
    if (value == null || value === "") return true
    // eslint-disable-next-line no-irregular-whitespace
    return value.match(/^[ァ-ヶー\s]+$/)
  })
})

const url = Yup.string().matches(
  /(https?:\/\/(www.)?[\w\-\\.\\/\\?\\,%&=\\#\\:\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+)/g,
  "正しいURLを入力してください。（例：https://example.com ）"
)

/**
 * ログイン用
 */
export const LOGIN_FORM_VALIDATION = Yup.object().shape({
  email: Yup.string()
    // .email("正しいメールアドレスを入力してください")
    .required("メールアドレスまたはPlus member IDを入力してください"),
  password: Yup.string().required("パスワードを入力してください"),
  keepLogIn: Yup.boolean(),
})
/**
 * パスワード再発行用
 */
export const FORGOT_PASSWORD_FORM_VALIDATION = Yup.object().shape({
  email: Yup.string()
    .email("正しいメールアドレスを入力してください")
    .required("メールアドレスを入力してください"),
})
/**
 * パスワード再設定用
 */
export const RESET_PASSWORD_FORM_VALIDATION = Yup.object().shape({
  password: Yup.string()
    .required("パスワードを入力してください")
    .min(8, "パスワードは8文字以上を指定してください"),
  passwordConfirmation: Yup.string()
    .required("パスワードを再入力してください")
    .oneOf([Yup.ref("password"), null], "パスワードが一致しません"),
})

/**
 * 個人アカウント登録 共通ロジック
 */
const GENERAL_FORM_VALIDATION_COMMON = {
  nickname: Yup.string().required("アカウント名を入力してください"),
  lastName: Yup.string().required("氏を入力してください"),
  firstName: Yup.string().required("名を入力してください"),
  lastNameKana: Yup.string()
    .hiragana()
    .required("氏（ふりがな）を入力してください"),
  firstNameKana: Yup.string()
    .hiragana()
    .required("名（ふりがな）を入力してください"),
  birthDate: Yup.string()
    .matches(
      /([0-9]{4})[-]([0-9]{2})[-]([0-9]{2})/,
      "生年月日の形式に誤りがあります (例 1990-01-11)"
    )
    .test(
      "inputFormat",
      "生年月日の形式に誤りがあります (例 1990-01-11)",
      (dateString) => isValid(new Date(dateString))
    )
    .required("生年月日を入力してください"),
  email: Yup.string()
    .email("正しいメールアドレスを入力してください")
    .required("メールアドレスを入力してください"),
  emailConfirmation: Yup.string()
    .email("正しいメールアドレスを入力してください")
    .required("確認用メールアドレスを入力してください")
    .oneOf([Yup.ref("email")], "メールアドレスが一致しません"),
}

/**
 * 新規個人アカウント登録用
 */
export const GENERAL_REGISTER_FORM_VALIDATION = Yup.object().shape({
  ...GENERAL_FORM_VALIDATION_COMMON,
  password: Yup.string()
    .min(8, "パスワードは8文字以上を指定してください")
    .required("パスワードを入力して下さい"),
  passwordConfirmation: Yup.string()
    .required("パスワードを再入力してください")
    .oneOf([Yup.ref("password"), null], "パスワードが一致しません"),
})

/**
 * 新規個人アカウント更新用
 */
export const GENERAL_UPDATE_FORM_VALIDATION = Yup.object().shape({
  ...GENERAL_FORM_VALIDATION_COMMON,
  password: Yup.string().min(8, "パスワードは8文字以上を指定してください"),
  passwordConfirmation: Yup.string().when("password", (value, schema) =>
    typeof value !== "undefined" && value.length > 0
      ? schema
          .required("パスワードを再入力してください")
          .oneOf([Yup.ref("password"), null], "パスワードが一致しません")
      : schema
  ),
})

/**
 * 新規組織アカウント申請用
 */
export const OFFICIAL_REGISTER_FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("公式アカウント名を入力してください"),
  nameKana: Yup.string()
    .hiragana()
    .required("公式アカウント名（ふりがな）を入力してください"),
  tel: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "電話番号の形式に誤りがあります"
    )
    .required("電話番号を入力してください"),
  address: Yup.string().required("住所を入力してください"),
})
/**
 * 新規ユーザー申請用
 */
export const USER_REGISTER_FORM_VALIDATION = Yup.object().shape({
  accountName: Yup.string().required("アカウント名を入力してください"),
  lastName: Yup.string().required("氏を入力してください"),
  firstName: Yup.string().required("名を入力してください"),
  lastNameFurigana: Yup.string()
    .hiragana()
    .required("氏（ふりがな）を入力してください"),
  firstNameFurigana: Yup.string()
    .hiragana()
    .required("名（ふりがな）を入力してください"),
  email: Yup.string()
    .email("正しいメールアドレスを入力してください")
    .required("メールアドレスを入力してください"),
  emailConfirmation: Yup.string()
    .email("正しいメールアドレスを入力してください")
    .required("確認用メールアドレスを入力してください")
    .oneOf([Yup.ref("email")], "メールアドレスが一致しません"),
  tel: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "電話番号の形式に誤りがあります"
    )
    .required("電話番号を入力してください"),
  password: Yup.string()
    .required("パスワードを入力してください")
    .min(8, "パスワードは8文字以上を指定してください"),
  passwordConfirmation: Yup.string()
    .required("パスワードを再入力してください")
    .oneOf([Yup.ref("password"), null], "パスワードが一致しません"),
})
/**
 * 振り込み口座申請用
 */
export const BANK_REGISTER_FORM_VALIDATION = Yup.object().shape({
  bankName: Yup.string().required("銀行名を入力してください"),
  branchName: Yup.string().required("支店名を入力してください"),
  accountType: Yup.string().required("口座種別を選んでください"),
  accountNumber: Yup.string()
    .matches(/^[0-9]+$/, "半角数字で入力してください")
    .max(7, "７桁以下の数字を入力してください")
    .required("口座番号を入力してください"),
  accountHolder: Yup.string()
    .matches(
      /^[ァ-ヶｦ-ﾟー\s()\-./,]+$/,
      "カタカナ・空白・記号【（）－，／．】のみ入力して下さい"
    )
    .required("口座名義人を入力してください"),
})
/**
 * 利用規約同意用
 */
export const ACCEPT_TERMS_FORM_VALIDATION = Yup.object().shape({
  accept: Yup.boolean()
    .required()
    .oneOf([true], "利用規約に同意の上、次へお進みください"),
  policy: Yup.boolean()
    .required()
    .oneOf([true], "プライバシーポリシーに同意の上、次へお進みください"),
})

/**
 * クレジットカード
 */
const creditCard = {
  cardHolder: Yup.string()
    .matches(/^[A-Za-z ]*$/, "半角英字で入力してください")
    .max(50, "50文字以内で入力してください")
    .required("カード名義人を入力してください"),
  cardNumber: Yup.string()
    .matches(/^[0-9]+$/, "半角数字で入力してください")
    .min(10, "正しいクレジットカード番号を入力してください")
    .max(16, "10-16桁で入力してください")
    .required("カード番号を入力してください"),
  cardMonth: Yup.string()
    .matches(/^([0-9]{2})$/, "半角数字で入力してください（例: 1月 → 01）")
    .transform((value, originalValue) =>
      originalValue.trim() > 12 ? null : value
    )
    .nullable()
    .required("有効期限を入力してください（例: 1月 → 01）"),
  cardYear: Yup.string()
    .matches(/^([0-9]{2})$/, "半角数字で入力してください（例: 2024年 → 24）")
    .transform((value, originalValue) =>
      originalValue.trim() < String(new Date().getFullYear()) ? null : value
    )
    .nullable()
    .required("有効期限を入力してください（例: 2024年 → 24）"),
  cvc: Yup.string()
    .matches(/^([0-9]{3,4})$/, "3桁又は4桁の半角数字で入力してください")
    .required("セキュリティコードを入力してください"),
}
/**
 * 支払い
 */
export const COMMON_PAYMENT_VALIDATION = {
  paymentMethod: Yup.string().required("お支払い方法を選択してください"),
  payment: Yup.string().when("paymentMethod", {
    is: (paymentMethod) => String(paymentMethod) === "1",
    then: Yup.string().required("お支払い方法を選んでください"),
  }),
  creditcard: Yup.object()
    .when("payment", {
      is: (val) => val === "newCredit",
      then: Yup.object().shape({ ...creditCard }),
    })
    .nullable(),
}
// 抽選、オークション支払い
export const PAYMENT_VALIDATION = Yup.object().shape({
  ...COMMON_PAYMENT_VALIDATION,
})
// 定価購入
export const LIST_VALIDATION = Yup.object().shape({
  ...COMMON_PAYMENT_VALIDATION,
  quantity: Yup.string().required("購入個数を選択してください"),
})
// オークション共通
export const COMMON_AUCTION_VALIDATION = {
  price: Yup.string()
    .required("入札価格を入力してください")
    .when("currencyMethod", {
      is: (val) => val?.toUpperCase() === "ETH",
      then: Yup.string()
        .matches(
          /^[0-9]+\.?\d{0,8}$/,
          "半角数字(小数点第8位以上)で入力してください"
        )
        .nullable()
        .transform((value, originalValue) =>
          String(originalValue).trim() === "" ? null : value
        )
        .test("auction-price", (value, { createError, path, parent }) => {
          if (Number(value) > AUCTION_MAX_OFFER_PRICE)
            return createError({
              path,
              message: `オークション最大入札金額の${AUCTION_MAX_OFFER_PRICE}}ETH以下の金額を入力してください`,
            })
          if (Number(value) < Number(parent.minBidPrice))
            return createError({
              path,
              message: `最低入札金額の${getCurrencyFormattWithoutSymbol(
                parent.minBidPrice,
                parent.currencyMethod
              )}ETH以上の金額を入力してください`,
            })
          if (parent.buyoutPrice && Number(value) > Number(parent.buyoutPrice))
            return createError({
              path,
              message: `即決価格${getCurrencyFormattWithoutSymbol(
                parent.buyoutPrice,
                parent.currencyMethod
              )}ETH以下の金額を入力してください`,
            })
          return true
        }),
    })
    .when("currencyMethod", {
      is: (val) => val?.toUpperCase() === "YEN",
      then: Yup.string()
        .matches(/^[0-9]+$/, "半角数字(カンマ無し)で入力してください！")
        .nullable()
        .transform((value, originalValue) =>
          String(originalValue).trim() === "" ? null : value
        )
        .test("auction-price", (value, { createError, path, parent }) => {
          if (Number(value) > AUCTION_MAX_OFFER_PRICE)
            return createError({
              path,
              message: `オークション最大入札金額の${toJPformatter.format(
                AUCTION_MAX_OFFER_PRICE
              )}円以下の金額を入力してください`,
            })
          if (
            parent.buyoutPrice !== null &&
            Number(value) === Number(parent.buyoutPrice)
          ) {
            // 即決価格を指定した場合のみ、elseif節の最低入札価格に関するバリデーションをスキップ
            // ex: 現在価格=999yen, 即決価格=1000円の際に、最低入札価格は999+10=1009円だが、1000円で即札できるように
          } else if (Number(value) < Number(parent.minBidPrice)) {
            // 最低入札価格以下の場合はエラー
            return createError({
              path,
              message: `最低入札金額の${toJPformatter.format(
                parent.minBidPrice
              )}円以上の金額を入力してください`,
            })
          }
          if (parent.buyoutPrice && Number(value) > Number(parent.buyoutPrice))
            return createError({
              path,
              message: `即決価格${toJPformatter.format(
                parent.buyoutPrice
              )}円以下の金額を入力してください`,
            })
          return true
        }),
    })
    .when("currencyMethod", {
      is: (val) => val?.toUpperCase() === "MATIC",
      then: Yup.string()
        .matches(
          /^[0-9]+\.?\d{0,8}$/,
          "半角数字(小数点第8位以上)で入力してください"
        )
        .nullable()
        .transform((value, originalValue) =>
          String(originalValue).trim() === "" ? null : value
        )
        .test("auction-price", (value, { createError, path, parent }) => {
          if (Number(value) > AUCTION_MAX_OFFER_PRICE)
            return createError({
              path,
              message: `オークション最大入札金額の${AUCTION_MAX_OFFER_PRICE}MATIC以下の金額を入力してください`,
            })
          if (Number(value) < Number(parent.minBidPrice))
            return createError({
              path,
              message: `最低入札金額の${getCurrencyFormattWithoutSymbol(
                parent.minBidPrice,
                parent.currencyMethod
              )}MATIC以上の金額を入力してください`,
            })
          if (parent.buyoutPrice && Number(value) > Number(parent.buyoutPrice))
            return createError({
              path,
              message: `即決価格${getCurrencyFormattWithoutSymbol(
                parent.buyoutPrice,
                parent.currencyMethod
              )}MATIC以下の金額を入力してください`,
            })
          return true
        }),
    }),
}
// オークション
export const AUCTION_VALIDATION = Yup.object().shape({
  ...COMMON_AUCTION_VALIDATION,
})
// オークション支払い（入札時）
export const AUCTION_PAYMENT_VALIDATION = Yup.object().shape({
  ...COMMON_PAYMENT_VALIDATION,
  ...COMMON_AUCTION_VALIDATION,
})
// 最大アップロードファイルサイズ
// 1024 * 1024 * 10 = 10MB
const FILE_SIZE = 10485760

// ファイル拡張子 CSV
const SUPPORTED_FILE_FORMATS = ["text/csv"]

// 最大アップロードファイルサイズ
// 1024×1024×1024 = 1GB
const FILE_IMAGE_SIZE = 1073741824

// 画像拡張子 png, jpeg, gif'
const SUPPORTED_IMAGE_FORMATS = ["image/png", "image/jpeg", "image/gif"]

// 動画拡張子 mp4'
const SUPPORTED_VIDEO_FORMATS = ["video/mp4"]

// 動画拡張子 mp3'
const SUPPORTED_AUDIO_FORMATS = ["audio/mpeg"]

// 3Dファイル拡張子
const SUPPORTED_3D_FORMATS = ["glb"]

// 圧縮ファイル拡張子'
const SUPPORTED_ZIP_FORMATS = [
  "application/zip",
  "application/x-zip-compressed",
]

/**
 * 画像アップロード
 */
const imageUploadForm = Yup.mixed()
  .test(
    "fileSize",
    "画像のサイズが大きすぎます。1GB以下をアップロードしてください",
    (value) =>
      value === undefined ||
      value === null ||
      typeof value === "string" ||
      (value && value.size <= FILE_IMAGE_SIZE)
  )
  .test(
    "fileFormat",
    "png, jpg, gif いづれかのファイルをアップロードしてください",
    (value) =>
      value === undefined ||
      value === null ||
      typeof value === "string" ||
      (value && SUPPORTED_IMAGE_FORMATS.includes(value.type))
  )
/**
 * 動画&画像アップロード
 */
const imageVideoUploadForm = Yup.mixed()
  .test(
    "fileSize",
    "サイズが大きすぎます。1GB以下をアップロードしてください",
    (value) =>
      value === undefined ||
      value === null ||
      typeof value === "string" ||
      (value && value.size <= FILE_IMAGE_SIZE)
  )
  .test(
    "fileFormat",
    "png, jpg, gif, mp4, glb いづれかのファイルをアップロードしてください",
    (value) =>
      value === undefined ||
      value === null ||
      typeof value === "string" ||
      [...SUPPORTED_3D_FORMATS].includes(value.path.split(".").pop()) ||
      (value &&
        [...SUPPORTED_IMAGE_FORMATS, ...SUPPORTED_VIDEO_FORMATS].includes(
          value.type
        ))
  )
/**
 * コンテンツアップロード
 */
const contentsUploadForm = Yup.mixed()
  .test(
    "fileSize",
    "サイズが大きすぎます。1GB以下をアップロードしてください",
    (value) =>
      value === undefined ||
      value === null ||
      typeof value === "string" ||
      (value && value.size <= FILE_IMAGE_SIZE)
  )
  .test(
    "fileFormat",
    "png, jpg, gif, mp4, mp3, glb, zip いづれかのファイルをアップロードしてください",
    (value) =>
      value === undefined ||
      value === null ||
      typeof value === "string" ||
      [...SUPPORTED_3D_FORMATS].includes(value.path.split(".").pop()) ||
      (value &&
        [
          ...SUPPORTED_IMAGE_FORMATS,
          ...SUPPORTED_VIDEO_FORMATS,
          ...SUPPORTED_AUDIO_FORMATS,
          ...SUPPORTED_ZIP_FORMATS,
        ].includes(value.type))
  )
/**
 * ファイルアップロード
 */
const fileUploadForm = Yup.mixed()
  .test(
    "fileSize",
    "ファイルのサイズが大きすぎます。10MB以下をアップロードしてください",
    (value) =>
      value === undefined ||
      typeof value === "string" ||
      (value && value.size <= FILE_SIZE)
  )
  .test(
    "fileFormat",
    "CSVのファイルをアップロードしてください",
    (value) =>
      value === undefined ||
      typeof value === "string" ||
      (value && SUPPORTED_FILE_FORMATS.includes(value.type))
  )
/**
 * NFT新規作成
 */
const nftNewForm = {
  coverImage: imageVideoUploadForm.required(
    "画像または動画をアップロードしてください"
  ),
  title: Yup.string().required("NFTタイトルを入力してください"),
  // detail: Yup.string().required("NFT概要を入力してください"),
  category: Yup.string().nullable(),
  brand: Yup.string().nullable(),
  web: url,
  salesMethod: Yup.string().required("販売方式を選択してください"),
  availablePaymentMethod: Yup.array().required("支払い方式を選択してください"),
  accept: Yup.boolean().when("availablePaymentMethod", {
    is: (val) => typeof val === "string" && val.includes("2"),
    then: Yup.boolean()
      .required()
      .oneOf([true], "上記留意事項に同意の上、次へお進みください"),
  }),
  // paymentMethod: Yup.string().required("支払い先を選択してください"),
  salesStartedAt: Yup.date()
    .default(() => new Date())
    .required("開始日時を選択してください")
    .nullable(),
  salesEndedAt: Yup.date()
    .default(() => new Date())
    .required("終了日時を選択してください")
    .min(Yup.ref("salesStartedAt"), "開始日時より後の日時を指定してください")
    .nullable(),
  story: Yup.mixed().required("アイテム詳細を入力してください"),
  royalty: Yup.string()
    .matches(/^[-]?([1-9]\d*|0)(\.\d+)?$/, "半角数字のみで入力してください")
    .required("ロイヤリティを100%以下で入力してください")
    .nullable()
    .transform((value, originalValue) =>
      originalValue.trim() > 100 ? null : value
    ),
  commission: Yup.string()
    .matches(/^[-]?([1-9]\d*|0)(\.\d+)?$/, "半角数字のみで入力してください")
    .required("手数料を100%以下で入力してください")
    .nullable()
    .transform((value, originalValue) =>
      originalValue.trim() > 100 ? null : value
    ),
  secondaryCommission: Yup.string()
    .matches(/^[-]?([1-9]\d*|0)(\.\d+)?$/, "半角数字のみで入力してください")
    .required("手数料を100%以下で入力してください")
    .nullable()
    .transform((value, originalValue) =>
      originalValue.trim() > 100 ? null : value
    ),
  publishedAt: Yup.date()
    .default(() => new Date())
    .required("公開開始日時を選択してください")
    .nullable(),
  expiredAt: Yup.date()
    .default(() => new Date())
    .required("公開終了日時を選択してください")
    .min(Yup.ref("publishedAt"), "公開開始日時より後の日時を指定してください")
    .nullable(),
  paymentDueDateAt: Yup.date()
    .when("salesMethod", {
      is: (val) => val === "auction",
      then: Yup.date()
        .default(() => new Date())
        // .required("入金期限を選択してください")
        .min(
          Yup.ref("salesEndedAt"),
          "オークション終了日時より後の日時を指定してください"
        )
        .test(
          "auction-payment-due-date-at",
          (value, { createError, path, parent }) => {
            if (
              parent.salesMethod === "auction" &&
              (isBefore(new Date(value), new Date(parent.salesEndedAt)) ||
                isEqual(new Date(value), new Date(parent.salesEndedAt)))
            ) {
              return createError({
                path,
                message: "オークション終了日時より後の日時を指定してください",
              })
            }
            return true
          }
        )
        .nullable(),
    })
    .nullable(),
  price: Yup.string()
    .when("availablePaymentMethod", {
      is: (val) =>
        val.length > 1 || val?.includes(PAYMENT_METHOD_MASTER.JPY_BY_CARD),
      then: Yup.string()
        .matches(/^((([1-9]\d*)(,\d{3})*)|0)$/, "半角数字で入力してください")
        .required("価格を500円以上、1千万円未満で入力してください")
        .nullable()
        .transform((value, originalValue) =>
          originalValue.trim() < 500 || originalValue.trim() >= 10000000
            ? null
            : value
        ),
    })
    .when("availablePaymentMethod", {
      is: (val) => val?.includes(PAYMENT_METHOD_MASTER.NOWPAYMENT),
      then: Yup.string()
        .matches(/^((([1-9]\d*)(,\d{3})*)|0)$/, "半角数字で入力してください")
        .required("価格を5000円以上、1千万円未満で入力してください")
        .nullable()
        .transform((value, originalValue) =>
          originalValue.trim() < 5000 || originalValue.trim() >= 10000000
            ? null
            : value
        ),
    })
    .when("availablePaymentMethod", {
      is: (val) => val?.length === 1 && isPayByMatic(val),
      then: Yup.string()
        .matches(
          /^([0-9]+\.?\d{0,8}|0)$/,
          "半角数字（小数点第8位以上）で入力してください"
        )
        .required("価格を0.00000001MATIC以上で入力してください")
        .nullable()
        .transform((value, originalValue) =>
          originalValue.trim() < 0.00000001 ? null : value
        ),
    })
    .when("availablePaymentMethod", {
      is: (val) => val?.length === 1 && isPayByEther(val),
      then: Yup.string()
        .matches(
          /^([0-9]+\.?\d{0,8}|0)$/,
          "半角数字（小数点第8位以上）で入力してください"
        )
        .required("価格を0.00000001ETH以上で入力してください")
        .nullable()
        .transform((value, originalValue) =>
          originalValue.trim() < 0.00000001 ? null : value
        ),
    }),
  buyoutPrice: Yup.string()
    .when(["salesMethod", "availablePaymentMethod"], {
      is: (sales, method) =>
        sales === "auction" &&
        method?.some(
          (v) =>
            v === PAYMENT_METHOD_MASTER.JPY_BY_CARD ||
            v === PAYMENT_METHOD_MASTER.NOWPAYMENT
        ),
      then: Yup.string()
        .matches(/^((([1-9]\d*)(,\d{3})*)|0)$/, "半角数字で入力してください")
        .test(
          "auction-buyout-price",
          (buyoutPrice, { createError, path, parent }) => {
            if (Number(buyoutPrice) < 500 || Number(buyoutPrice) > 9999999)
              return createError({
                path,
                message: "価格を500円以上、1千万円未満で入力してください",
              })
            if (Number(buyoutPrice) < Number(parent.price))
              return createError({
                path,
                message: "最低価格より高い価格を入力してください",
              })
            return true
          }
        )
        .nullable(),
    })
    .when(["salesMethod", "availablePaymentMethod"], {
      is: (sales, method) =>
        sales === "auction" &&
        method?.some((v) => isPayByCrypto(v)) &&
        !method?.some((v) =>
          [
            PAYMENT_METHOD_MASTER.JPY_BY_CARD,
            PAYMENT_METHOD_MASTER.NOWPAYMENT,
          ].includes(v)
        ),
      then: Yup.string()
        .matches(
          /^([0-9]+\.?\d{0,8}|0)$/,
          "半角数字（小数点第8位以上）で入力してください"
        )
        .test(
          "auction-buyout-price",
          (buyoutPrice, { createError, path, parent }) => {
            if (Number(buyoutPrice) < Number(parent.price))
              return createError({
                path,
                message: "最低価格より高い価格を入力してください",
              })
            return true
          }
        )
        .nullable(),
    })
    .nullable(),
  limitMaxPriceOnSecondarySelling: Yup.string()
    .when("availablePaymentMethod", {
      is: (val) => val?.includes(PAYMENT_METHOD_MASTER.JPY_BY_CARD),
      then: Yup.string()
        .matches(/^((([1-9]\d*)(,\d{3})*)|0)$/, "半角数字で入力してください")
        .test(
          "limit-max-price-on-secondary-selling",
          (buyoutPrice, { createError, path, parent }) => {
            if (Number(buyoutPrice) < 500 || Number(buyoutPrice) > 9999999)
              return createError({
                path,
                message: "価格を500円以上、1千万円未満で入力してください",
              })
            if (Number(buyoutPrice) < Number(parent.price))
              return createError({
                path,
                message: "最低価格より高い価格を入力してください",
              })
            return true
          }
        )
        .nullable(),
    })
    .when("availablePaymentMethod", {
      is: (val) => val?.includes(PAYMENT_METHOD_MASTER.NOWPAYMENT),
      then: Yup.string()
        .matches(/^((([1-9]\d*)(,\d{3})*)|0)$/, "半角数字で入力してください")
        .test(
          "limit-max-price-on-secondary-selling",
          (buyoutPrice, { createError, path, parent }) => {
            if (Number(buyoutPrice) < 5000 || Number(buyoutPrice) > 9999999)
              return createError({
                path,
                message: "価格を5000円以上、1千万円未満で入力してください",
              })
            if (Number(buyoutPrice) < Number(parent.price))
              return createError({
                path,
                message: "最低価格より高い価格を入力してください",
              })
            return true
          }
        )
        .nullable(),
    })
    .when("availablePaymentMethod", {
      is: (val) =>
        val?.some((v) => isPayByCrypto(v)) &&
        !val?.some((v) =>
          [
            PAYMENT_METHOD_MASTER.JPY_BY_CARD,
            PAYMENT_METHOD_MASTER.NOWPAYMENT,
          ].includes(v)
        ),
      then: Yup.string()
        .matches(
          /^([0-9]+\.?\d{0,8}|0)$/,
          "半角数字（小数点第8位以上）で入力してください"
        )
        .test(
          "limit-max-price-on-secondary-selling",
          (buyoutPrice, { createError, path, parent }) => {
            if (Number(buyoutPrice) < Number(parent.price))
              return createError({
                path,
                message: "最低価格より高い価格を入力してください",
              })
            return true
          }
        )
        .nullable(),
    }),
  quantity: Yup.string().when("salesMethod", {
    is: (val) => val === "list" || val === "lottery",
    then: Yup.string().required("NFT発行数を選択してください"),
  }),
  purchaseLimit: Yup.string().when("salesMethod", {
    is: (val) => val === "list",
    then: Yup.string()
      .matches(/^((([1-9]\d*)(,\d{3})*)|0)$/, "半角数字で入力してください")
      .test({
        test: (antal) => {
          if (antal === undefined) {
            return true
          }
          return Number(antal) >= 1
        },
        message: "１以上の数値を入力してください",
      })
      .required("１人ユーザーあたりの購入数の制限を入力してください"),
  }),
  file: Yup.mixed().when(["salesMethod", "quantity"], {
    is: (salesMethod, quantity) =>
      salesMethod === "auction" || quantity === "3",
    then: fileUploadForm,
  }),
  serialNumber: Yup.array()
    .when("salesMethod", {
      is: (val) => val === "auction",
      then: Yup.array().max(1, "シリアルナンバーを１個登録してください"),
    })
    .when("quantity", {
      is: (val) => val === "3",
      then: Yup.array().min(1, "CSVファイルをアップロードしてください。"),
    }),
  multipleSell: Yup.string().when("quantity", {
    is: (val) => val === "2",
    then: Yup.string()
      .matches(/^[0-9]+$/, "半角数字で入力してください")
      .required("NFT発行数を入力してください"),
  }),
  multipleSellWithSerial: Yup.string().when("quantity", {
    is: (val) => val === "3",
    then: Yup.string().test({
      test: (antal, context) => {
        if (antal === undefined || context.parent.serialNumber.length > 0) {
          return true
        }
        return typeof antal !== "string"
      },
      message:
        "ファイルをアップロードしてください。アップロードファイルに応じて個数は自動で設定されます。",
    }),
  }),
  limitedSellingConditionRule: Yup.string().when("limitedSellingConditions", {
    is: (val) => val,
    then: Yup.string().required("条件を選択してください。"),
  }),
  limitedSellingConditionRuleKitRooms: Yup.string().when(
    "limitedSellingConditionsKitRooms",
    {
      is: (val) => val,
      then: Yup.string().required("条件を選択してください。"),
    }
  ),
  limitedSellingConditionRuleNftOwned: Yup.string()
    .when("limitedSellingConditionsNftOwned", {
      is: (val) => val,
      then: Yup.string().required("条件を選択してください。"),
    })
    .when("limitedSellingConditionExpiredAtNftOwned", {
      is: (val) => val,
      then: Yup.string().required("条件を選択してください。"),
    }),
  limitedSellingConditionsNftOwned: Yup.string()
    .when("limitedSellingConditionExpiredAtNftOwned", {
      is: (val) => val,
      then: Yup.string().required("NFT IDを入力してください。"),
    })
    .nullable(),
}

export const NFT_FORM_VALIDATION = Yup.object().shape({
  ...nftNewForm,
})

/**
 * NFT複製
 */
export const NFT_DUPLICATE_VALIDATION = Yup.object().shape({
  numberOfCopy: Yup.number()
    .required("複製個数を指定してください")
    .min(1, "複製個数は1以上で指定してください"),
})

/**
 * NFT限定コンテンツ新規作成
 */
const nftPrivateMediaContentNewForm = {
  title: Yup.string().required("コンテンツ名称を入力してください"),
  startViewableAt: Yup.date()
    .default(() => new Date())
    .nullable(),
  endViewableAt: Yup.date()
    .default(() => new Date())
    .min(Yup.ref("startViewableAt"), "公開開始日より後の日時を指定してください")
    .nullable(),
  mediaUrl: contentsUploadForm.required(
    "画像、音声、動画、ZIPのいずれかをアップロードしてください"
  ),
  contentType: Yup.string().required("ファイル形式を選択してください"),
}

export const NFT_PRIVATEMEDIACONTENT_FORM_VALIDATION = Yup.object().shape({
  ...nftPrivateMediaContentNewForm,
})

const secondaryNftBaseForm = {
  availablePaymentMethod: Yup.array().min(1, "支払い方式を選択してください"),
  salesMethod: Yup.string().required("販売方式を選択してください"),
  salesStartedAt: Yup.date()
    .default(() => new Date())
    .required("開始日時を選択してください")
    .nullable(),
  salesEndedAt: Yup.date()
    .default(() => new Date())
    .required("終了日時を選択してください")
    .min(Yup.ref("salesStartedAt"), "開始日時より後の日時を指定してください")
    .when("salesMethod", {
      is: (val) => val === "auction",
      then: Yup.date()
        .default(() => new Date())
        .test(
          "secondary-auction-sales-ended-at",
          (value, { createError, path, parent }) => {
            const limitMax = addDays(new Date(parent.salesStartedAt), 14)
            if (isAfter(new Date(value), limitMax)) {
              return createError({
                path,
                message:
                  "オークション終了日時は開始日時から起算して最大14日間(336時間)以内で指定ください",
              })
            }
            return true
          }
        )
        .nullable(),
    })
    .nullable(),
  price: Yup.string()
    .when("availablePaymentMethod", {
      is: (val) =>
        val?.length > 1 || val?.includes(PAYMENT_METHOD_MASTER.JPY_BY_CARD),
      then: Yup.string()
        .matches(/^((([1-9]\d*)(,\d{3})*)|0)$/, "半角数字で入力してください")
        .required("価格を500円以上、1千万円未満で入力してください")
        .test(
          "limit-max-price-on-secondary-selling",
          (value, { createError, path, parent }) => {
            // 二次出品上限額がnullの時は判定しない
            if (
              parent.limitMaxPriceOnSecondarySelling &&
              parent.price > parent.limitMaxPriceOnSecondarySelling
            ) {
              return createError({
                path,
                message: "二次出品上限額よりも低い額を設定してください",
              })
            }
            return true
          }
        )
        .nullable()
        .transform((value, originalValue) =>
          originalValue.trim() < 500 || originalValue.trim() >= 10000000
            ? null
            : value
        ),
    })
    .when("availablePaymentMethod", {
      is: (val) => val?.includes(PAYMENT_METHOD_MASTER.NOWPAYMENT),
      then: Yup.string()
        .matches(/^((([1-9]\d*)(,\d{3})*)|0)$/, "半角数字で入力してください")
        .required("価格を5000円以上、1千万円未満で入力してください")
        .test(
          "limit-max-price-on-secondary-selling",
          (value, { createError, path, parent }) => {
            // 二次出品上限額がnullの時は判定しない
            if (
              parent.limitMaxPriceOnSecondarySelling &&
              parent.price > parent.limitMaxPriceOnSecondarySelling
            ) {
              return createError({
                path,
                message: "二次出品上限額よりも低い額を設定してください",
              })
            }
            return true
          }
        )
        .nullable()
        .transform((value, originalValue) =>
          originalValue.trim() < 5000 || originalValue.trim() >= 10000000
            ? null
            : value
        ),
    })
    .when("availablePaymentMethod", {
      is: (val) => val?.length === 1 && isPayByMatic(val),
      then: Yup.string()
        .matches(
          /^([0-9]+\.?\d{0,8}|0)$/,
          "半角数字（小数点第8位以上）で入力してください"
        )
        .required("価格を0.00000001MATIC以上で入力してください")
        .test(
          "limit-max-price-on-secondary-selling",
          (value, { createError, path, parent }) => {
            // 二次出品上限額がnullの時は判定しない
            if (
              parent.limitMaxPriceOnSecondarySelling &&
              parent.price > parent.limitMaxPriceOnSecondarySelling
            ) {
              return createError({
                path,
                message: "二次出品上限額よりも低い額を設定してください",
              })
            }
            return true
          }
        )
        .nullable()
        .transform((value, originalValue) =>
          originalValue.trim() < 0.00000001 ? null : value
        ),
    })
    .when("availablePaymentMethod", {
      is: (val) => val?.length === 1 && isPayByEther(val),
      then: Yup.string()
        .matches(
          /^([0-9]+\.?\d{0,8}|0)$/,
          "半角数字（小数点第8位以上）で入力してください"
        )
        .required("価格を0.00000001ETH以上で入力してください")
        .test(
          "limit-max-price-on-secondary-selling",
          (value, { createError, path, parent }) => {
            // 二次出品上限額がnullの時は判定しない
            if (
              parent.limitMaxPriceOnSecondarySelling &&
              parent.price > parent.limitMaxPriceOnSecondarySelling
            ) {
              return createError({
                path,
                message: "二次出品上限額よりも低い額を設定してください",
              })
            }
            return true
          }
        )
        .nullable()
        .transform((value, originalValue) =>
          originalValue.trim() < 0.00000001 ? null : value
        ),
    }),
  buyoutPrice: Yup.string()
    .when(["salesMethod", "availablePaymentMethod"], {
      is: (sales, method) =>
        sales === "auction" &&
        method?.some(
          (v) =>
            v === PAYMENT_METHOD_MASTER.JPY_BY_CARD ||
            v === PAYMENT_METHOD_MASTER.NOWPAYMENT
        ),
      then: Yup.string()
        .matches(/^((([1-9]\d*)(,\d{3})*)|0)$/, "半角数字で入力してください")
        .test(
          "limit-max-price-on-secondary-selling",
          (value, { createError, path, parent }) => {
            // 二次出品上限額がnullの時は判定しない
            if (
              parent.limitMaxPriceOnSecondarySelling &&
              parent.buyoutPrice > parent.limitMaxPriceOnSecondarySelling
            ) {
              return createError({
                path,
                message: "二次出品上限額よりも低い額を設定してください",
              })
            }
            if (parent.buyoutPrice < 500 || parent.buyoutPrice > 9999999) {
              return createError({
                path,
                message: "価格を500円以上、1千万円未満で入力してください",
              })
            }
            if (Number(parent.buyoutPrice) < Number(parent.price))
              return createError({
                path,
                message: "最低価格より高い価格を入力してください",
              })
            return true
          }
        )
        .nullable(),
    })
    .when(["salesMethod", "availablePaymentMethod"], {
      is: (sales, method) =>
        sales === "auction" && method?.some((v) => isPayByCrypto(v)),
      then: Yup.string()
        .matches(
          /^([0-9]+\.?\d{0,8}|0)$/,
          "半角数字（小数点第8位以上）で入力してください"
        )
        .test(
          "limit-max-price-on-secondary-selling",
          (value, { createError, path, parent }) => {
            // 二次出品上限額がnullの時は判定しない
            if (
              parent.limitMaxPriceOnSecondarySelling &&
              parent.buyoutPrice > parent.limitMaxPriceOnSecondarySelling
            ) {
              return createError({
                path,
                message: "二次出品上限額よりも低い額を設定してください",
              })
            }
            if (Number(parent.buyoutPrice) < Number(parent.price))
              return createError({
                path,
                message: "最低価格より高い価格を入力してください",
              })
            return true
          }
        )
        .nullable(),
    }),
  terms: Yup.boolean()
    .required()
    .oneOf([true], "利用規約に同意の上、次へお進みください"),
  accept: Yup.boolean().when("availablePaymentMethod", {
    is: (val) => typeof val === "string" && val.includes("2"),
    then: Yup.boolean()
      .required()
      .oneOf([true], "上記留意事項に同意の上、次へお進みください"),
  }),
  // currencyMethod: Yup.string().required("支払い方式を選択してください"),
}

export const SECONDARY_NFT_FORM_VALIDATION = Yup.object().shape({
  ...secondaryNftBaseForm,
})

/**
 * 公演管理
 */
export const CONCERT_EDIT_VALIDATION = Yup.object().shape({
  coverImage: imageUploadForm.required(
    "画像または動画をアップロードしてください"
  ),
  listingStartedAt: Yup.date()
    .default(() => new Date())
    .min(Yup.ref("concertStartTime"), "開演日時より後の日時を指定してください")
    .nullable(),
})

/**
 * NFT管理
 * 特典管理
 */
export const NFT_MANAGE_EDIT_VALIDATION = Yup.object().shape({
  image: imageUploadForm.required("画像をアップロードしてください"),
  title: Yup.string().required("特典タイトルを入力してください"),
  detail: Yup.string().required("特典概要を入力してください"),
  web: url,
  expire: Yup.date()
    .default(() => new Date())
    .required("有効期限を選択してください")
    .nullable(),
  distributionMethod: Yup.string().required("配布形式を選択してください"),
  story: Yup.mixed().required("ストーリーを入力してください"),
})

/**
 * 組織アカウント管理/口座・カード情報
 * TODO 残りバリデーション追加
 */

export const CREDIT_VALIDATION = Yup.object().shape({ ...creditCard })

/**
 * お知らせ・速報管理
 * 新規作成
 * 編集
 */
export const NOTIFICATION_FORM_VALIDATION = Yup.object().shape({
  messageType: Yup.string().required("メッセージタイプを選択してください"),
  sendType: Yup.array(
    Yup.string().oneOf(["all", "official", "user", "member", "non_member"])
  ).min(1, "宛先を選択してください"),
  publishedAt: Yup.date()
    .default(() => new Date())
    .required("公開日時を選択してください")
    .nullable(),
  expiredAt: Yup.date()
    .default(() => new Date())
    .required("掲載終了日時を選択してください")
    .min(Yup.ref("publishedAt"), "公開日時より後の日時を指定してください")
    .nullable(),
  _has_description: Yup.bool().oneOf([true], "メッセージを入力してください"),
})

/**
 * マイページ
 * プロフィール
 */
export const MY_PROFILE_VALIDATION = Yup.object().shape({
  profilePicUrl: imageUploadForm.notRequired(),
  url1: url.nullable(),
  url2: url.nullable(),
  url3: url.nullable(),
  url4: url.nullable(),
  url5: url.nullable(),
  twitter: url.nullable(),
  facebook: url.nullable(),
  instagram: url.nullable(),
  tikTok: url.nullable(),
})

export const BRAND_FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("ブランド名を入力してください"),
})

export const CATEGORY_FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("カテゴリ名を入力してください"),
  urlParameter: Yup.string()
    .matches(/^([a-z\d]){1,}$/i, {
      message: "半角英数字で入力してください",
    })
    .required("URLパラメータを入力してください"),
  startViewableAt: Yup.date()
    .default(() => new Date())
    .required("表示開始日時を選択してください")
    .nullable(),
  endViewableAt: Yup.date()
    .default(() => new Date())
    .required("表示終了日時を選択してください")
    .min(
      Yup.ref("startViewableAt"),
      "表示開始日時より後の日時を指定してください"
    )
    .nullable(),
})

export const BANNER_FORM_VALIDATION = Yup.object().shape({
  link: url.required("リンク先URLを入力してください"),
  image: Yup.mixed().required("画像をアップロードしてください"),
  startViewableAt: Yup.date()
    .default(() => new Date())
    .required("表示開始日時を選択してください")
    .nullable(),
  endViewableAt: Yup.date()
    .default(() => new Date())
    .required("表示終了日時を選択してください")
    .min(
      Yup.ref("startViewableAt"),
      "表示開始日時より後の日時を指定してください"
    )
    .nullable(),
})

/**
 * 問い合わせフォーム
 */
export const CONTACT_FORM_VALIDATION = Yup.object().shape({
  lastName: Yup.string().required("名を入力してください"),
  firstName: Yup.string().required("姓を入力してください"),
  lastNameKana: Yup.string().katakana(),
  firstNameKana: Yup.string().katakana(),
  zipcode: Yup.string().matches(
    /^[0-9]{3}-[0-9]{4}$/,
    "郵便番号の形式に誤りがあります"
  ),
  prefecture: Yup.string(),
  city: Yup.string(),
  address: Yup.string(),
  addressOther: Yup.string(),
  tel: Yup.string().matches(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    "電話番号の形式に誤りがあります"
  ),
  email: Yup.string()
    .email("正しいメールアドレスを入力してください")
    .required("メールアドレスを入力してください"),
  content: Yup.string().required("お問い合わせ内容を入力してください"),
})

export const SEARCH_FORM_VALIDATION = Yup.object().shape({
  keyword: Yup.string().when("detail", {
    is: (val) =>
      val !== undefined && val.length > 0 && val.includes("product_name"),
    then: Yup.string().required("商品名を入力してください"),
  }),
})

// オファー
export const OFFER_VALIDATION = Yup.object().shape({
  offerAmount: Yup.number()
    .required("オファーする価格を入力してください")
    .min(0, "0より大きい値を入力してください"),
})
