import {
  PURCHASE_INIT,
  PURCHASE_ERROR,
  PURCHASE_NFT_LOADED,
  PURCHASE_NFT_ERROR,
  USER_CREDIT_LIST_LOADED,
  USER_CREDIT_LIST_ERROR,
  USER_BANK_LIST_LOADED,
  USER_BANK_LIST_ERROR,
  USER_WALLET_LIST_LOADED,
  USER_WALLET_LIST_ERROR,
  MARKET_ADDRESS_LOADED,
  PURCHASE_COMPLETE_LOADED,
  PURCHASE_COMPLETE_ERROR,
  PURCHASE_COMPLETE_BLOCKCHAIN_POST,
  PURCHASE_COMPLETE_BLOCKCHAIN_ERROR,
  LOCK_PAYMENT_METHOD_POST,
} from "./types"
import { getCurrencyFormattWithoutSymbol } from "../../utils/helper"

function initialState() {
  return {
    form: {
      quantity: 1,
      creditId: 0,
      walletId: 0,
      price: "",
      currencyMethod: "",
      paymentMethod: "",
      payment: "",
      creditcard: {
        cardNumber: "",
        cardHolder: "",
        cardMonth: "",
        cardYear: "",
        cvc: "",
      },
      walletAddress: "",
      token: "",
      minBidPrice: "",
    },
    nft: {
      salesStartedAt: null,
      salesEndedAt: null,
      paymentDueDateAt: null,
      official: {},
      user: null,
      auction: null,
      resultAnnouncement: false,
      ecdsaR: "",
      ecdsaS: "",
      ecdsaV: "",
      orderId: null,
      sellerAddress: "",
      marketAddress: "",
      officialAddress: "",
      coverImage: "",
      userOfferedAmount: null,
      isSalesStarted: true,
      isExpiredPaymentDueDate: false,
      limitMaxPriceOnSecondarySelling: "",
      contractAddress: null,
      contractAbi: null,
    },
    transaction: {
      orderId: "",
      price: "",
      paymentMethodMasterId: "",
      currencyMethod: "",
    },
    creditList: [],
    walletList: [],
    error: null,
  }
}

// payloadの内容からpriceの値を計算
// 現状ではオークション入札時の価格入力にのみ使用する
const calcPrice = (nft) => {
  let { price } = nft
  if (nft.salesMethod === "auction") {
    // 自分が過去に落札済みの場合は落札価格
    if (nft.auction === "won") {
      price = nft.maxAmount
    } else if (nft.buyoutPrice) {
      // 即決価格がある場合、minBidPriceとbuyoutPriceのうち小さい方
      price = Math.min(nft.minBidPrice, nft.buyoutPrice)
    } else {
      // 即決価格がない場合はminBidPrice
      price = nft.minBidPrice
    }
  }

  return getCurrencyFormattWithoutSymbol(price, nft.currencyMethod)
}

const purchaseReducers = (state = initialState(), action) => {
  switch (action.type) {
    case PURCHASE_INIT:
      return {
        ...state,
        form: initialState().form,
        gmoError: null,
      }
    case PURCHASE_NFT_LOADED: {
      // オークションの入札価格を数値で入力するためにpriceはnumber型に変換する
      const priceFormatted = calcPrice(action.payload)
      const priceNum = Number(priceFormatted.replace(/,/g, ""))

      return {
        ...state,
        nft: {
          ...state.nft,
          ...action.payload,
          orderId: null,
        },
        form: {
          ...state.form,
          currencyMethod: action.payload.currencyMethod, // ?? getDisplayCurrency(action.payload),
          price: priceNum,
          minBidPrice:
            action.payload.auction === "won"
              ? action.payload.maxAmount
              : action.payload.minBidPrice,
          maticPrice: action.payload.maticPrice,
          ethPrice: action.payload.ethPrice,
          buyoutPrice: action.payload.buyoutPrice,
          limitMaxPriceOnSecondarySelling:
            action.payload.limitMaxPriceOnSecondarySelling,
        },
      }
    }
    case USER_CREDIT_LIST_LOADED:
      return {
        ...state,
        creditList: action.payload.map((data) => data),
      }
    case USER_BANK_LIST_LOADED:
      return {
        ...state,
        bankList: action.payload.map((data) => data),
      }
    case USER_WALLET_LIST_LOADED:
      return {
        ...state,
        walletList: action.payload.map((data) => data),
      }
    case PURCHASE_COMPLETE_BLOCKCHAIN_POST:
      return {
        ...state,
        nft: {
          ...state.nft,
          auction: null,
          orderId: action.payload?.orderId,
          lottery: action.payload?.nft.lottery,
        },
      }
    case MARKET_ADDRESS_LOADED:
      return {
        ...state,
        nft: {
          ...state.nft,
          sellerAddress: action.payload.sellerAddress,
          marketAddress: action.payload.marketAddress,
          officialAddress: action.payload.officialAddress,
        },
      }
    case PURCHASE_COMPLETE_LOADED:
      return {
        ...state,
        nft: action.payload.nft,
        invoice: action.payload.invoice,
        transaction: action.payload.transaction,
      }
    case PURCHASE_NFT_ERROR:
      return {
        ...initialState(),
        error: action.payload,
      }
    case USER_CREDIT_LIST_ERROR:
    case PURCHASE_ERROR:
    case USER_BANK_LIST_ERROR:
    case USER_WALLET_LIST_ERROR:
    case PURCHASE_COMPLETE_ERROR:
    case PURCHASE_COMPLETE_BLOCKCHAIN_ERROR:
      throw new Error()
    case LOCK_PAYMENT_METHOD_POST:
    default:
      return state
  }
}

export default purchaseReducers
