import { createTheme } from "@material-ui/core/styles"

export const theme = createTheme({
  // カラーパレット追加
  palette: {
    accent: "linear-gradient(225deg, #2400FF 0%, #8F00FF 100%)",
    main: "#333",
    bg_dark: "#9D9D9D",
    bg_light: "#EEEEEE",
    bg_base: "#FFFFFF",
    danger: "#DA0C3D",
    text_dark: "#000000",
    purple: "#8F00FF",
    bg_search: "#F9F9F9",
  },
  breakpoints: {
    keys: ["xs", "sm", "md", "lg"],
    values: {
      xs: 0, // スマホ用
      sm: 767, // タブレット用
      md: 1140, // PC用
      lg: 1400,
    },
  },
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Helvetica",
      "Roboto",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiPaper: {
      root: {
        color: "#333",
      },
    },
    MuiButton: {
      root: {
        padding: "8px 20px",
        margin: "0 5px",
        borderRadius: "60px",
        border: "1px solid #333",
        textTransform: "none",
        fontSize: "20px",
        lineHeight: "1.1",
        boxSizing: "border-box",
      },
      text: {
        // padding: "3px 20px",
        padding: "8px 20px 8px",
      },
      label: {
        fontSize: "14px",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
        padding: "5px",
        fontSize: "15px",
        color: "#333",
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
        },
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        width: "100%",
        maxWidth: "900px",
      },
      // colorTextPrimary: {
      //   color: "#333333",
      // },
    },
    MuiInputBase: {
      root: {
        "& input": {
          height: "38px",
          padding: "6px 16px",
          borderRadius: "3px",
          verticalAlign: "middle",
          boxSizing: "border-box",

          "&:disabled": {
            color: "#333",
          },
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: "6px",
        "& .Mui-error": {
          "&:last-child": {
            margin: "1px 0 -16px",
            fontSize: "13px",
            lineHeight: "1.4",
            textAlign: "right",
            "@media (max-width: 767px)": {
              marginTop: "4px",
            },
          },
        },
        "& .MuiInputLabel-formControl": {
          padding: "0 10px",
          "@media (max-width: 767px)": {
            fontSize: "14px",
          },
        },
      },
    },
    MuiContainer: {
      root: {
        "& form": {
          marginBottom: "14px",
        },
        "& .login-prob": {
          marginBottom: "10px",
          fontSize: "13px",
          lineHeight: "1.6",
          fontWeight: "400",
          "@media (max-width: 767px)": {
            fontSize: "14px",
          },
          "& span:hover": {
            textDecoration: "underline",
          },
          "& span": {
            color: "#8b8b8b",
            fontWeight: "400",
            textDecoration: "underline",
          },
          "&.login-prob-other": {
            marginTop: "24px",
            "& span": {
              color: "#333",
            },
          },
        },
        "& .code-prob, & .note": {
          marginBottom: "20px",
          fontSize: "12px",
          lineHeight: "1.3",
        },
        "& .note": {
          fontSize: "12px",
        },
        "& .MuiInputLabel-filled.MuiInputLabel-marginDense,& .MuiFormControl-root .MuiInputLabel-formControl.MuiInputLabel-filled":
          {
            transform: "translate(0, 13px) scale(1)",
            "&.MuiInputLabel-shrink": {
              transform: "translate(0, -16px) scale(0.75)",
              transformOrigin: "top left",
            },
            "&.Mui-error": {
              color: "transparent",
            },
          },

        "& .accountType": {
          display: "flex",
          padding: "10px",
          flexDirection: "row",
          justifyContent: "space-between",
          "& .MuiFormControlLabel-root": {
            width: "48%",
            margin: "0",
            "& .MuiRadio-root": {
              display: "none",
            },
            "& .MuiTypography-root": {
              height: "100%",
              padding: "10px",
              border: "1px solid #333",
              borderRadius: "6px",
              "& .MuiIcon-root": {
                display: "block",
                width: "100%",
                fontSize: "50px",
                textAlign: "center",
              },
              "& .typeName": {
                margin: "3px 0 8px",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#333",
                textAlign: "center",
              },
              "& p": {
                fontSize: "12px",
                color: "#9D9D9D",
                lineHeight: "1.3",
              },
            },
            "& .Mui-checked + .MuiFormControlLabel-label": {
              color: "#fff",
              backgroundColor: "#333",
              "& p": {
                color: "#fff",
              },
            },
          },
        },

        "& .MuiCheckbox-colorSecondary": {
          color: "#000",
          opacity: "0.5",
          "&.Mui-checked": {
            opacity: "1",
          },
        },
      },
    },
    MuiGrid: {
      root: {
        "& .MuiButtonBase-root:last-child": {
          // marginBottom: "40px",
        },

        "& .table-container": {
          "& .MuiButtonBase-root": {
            padding: "11px 20px 9px",
            marginBottom: "0",
          },
        },
        "& .MuiAvatar-root, img, video": {
          pointerEvents: "none",
        },

        // CommonLayout
        "&.common-layout": {
          width: "100%",
          padding: "0 50px 150px",
          margin: "0",
          justifyContent: "space-between",
          boxSizing: "border-box",

          "@media (max-width: 1200px)": {
            padding: "20px 20px 100px",
          },

          "@media (max-width: 600px)": {
            padding: "20px 10px 100px",
          },

          "& .common-sidemenu": {
            width: "300px",
            maxWidth: "300px",
            flexBasis: "inherit",

            "@media (max-width: 860px)": {
              width: "220px",
              maxWidth: "220px",

              "& .MuiPaper-root": {
                width: "220px",

                "& .MuiBox-root:first-child": {
                  padding: "10px",

                  "& .MuiAvatar-root": {
                    // width: "80px",
                    // height: "80px",
                    marginRight: "10px",
                  },

                  "& .MuiBox-root": {
                    padding: "0",
                    margin: "0",
                  },
                },

                "& .MuiBox-root:nth-child(2)": {
                  padding: "0 10px",
                },
              },
            },

            "@media (max-width: 767px)": {
              display: "none",
            },

            "& > .MuiBox-root": {
              "& > img": {
                width: "100%",
                marginBottom: "12px",
              },
            },
          },

          "& .lit-image": {
            position: "relative",
            "& img": {
              display: "block",
              margin: "0 auto",
            },
            "& > img": {
              width: "100%",
              marginBottom: "4px",
            },

            "& .lit-modal": {
              position: "fixed",
              display: "flex",
              top: "0",
              left: "0",
              width: "100%",
              height: "100vh",
              padding: "80px 40px",
              background: "rgba(0,0,0,0.8)",
              alignItems: "center",
              justifyContent: "center",
              transition: "0.3s",
              zIndex: "9999",
              pointerEvents: "none",
              "& img": {
                position: "relative",
                zIndex: "1",
              },
              "& canvas": {
                pointerEvents: "auto",
              },
              "& .close": {
                position: "absolute",
                display: "flex",
                top: "20px",
                right: "20px",
                width: "40px",
                height: "40px",
                color: "#FFF",
                alignItems: "center",
                justifyContent: "center",
                pointerEvents: "auto",
              },
              "& .lit-modal-main": {
                display: "block",
                maxWidth: "100%",
                height: "100%",
                "& img,& video,& div": {
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                },
                "& img": {
                  pointerEvents: "none",
                },
                "& video": {
                  pointerEvents: "auto",
                },
              },
            },
          },

          "& .common-maincontent": {
            width: "calc(100% - 350px)",
            maxWidth: "calc(100% - 350px)",
            flexBasis: "inherit",

            "@media (max-width: 1200px)": {
              width: "calc(100% - 332px)",
              maxWidth: "calc(100% - 332px)",
            },

            "@media (max-width: 860px)": {
              width: "calc(100% - 240px)",
              maxWidth: "calc(100% - 240px)",
            },

            "@media (max-width: 767px)": {
              width: "100%",
              maxWidth: "100%",
              "& > .MuiBox-root": {
                "&:first-child": {
                  marginBottom: "20px",
                  "& .MuiTypography-root": {
                    fontSize: "20px",
                    "&.MuiTypography-colorTextSecondary": {
                      marginBottom: "2px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    },
                  },
                  "& + .MuiDivider-root": {
                    marginBottom: "-10px",
                  },
                },
                "& > .MuiBox-root": {
                  "& > .MuiButtonBase-root": {
                    position: "fixed",
                    display: "flex",
                    right: "2.5%",
                    bottom: "80px",
                    width: "95%",
                    padding: "4px",
                    margin: "0",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: "100",
                    "& .MuiButton-label": {
                      fontSize: "12px",
                    },
                  },
                },
              },
              "& .MuiGrid-root.MuiGrid-container": {
                "& form": {
                  "& > .MuiGrid-root:first-child": {
                    "& > .MuiBox-root:first-child": {
                      "& > .MuiBox-root:last-child": {
                        position: "fixed",
                        display: "flex",
                        right: "2.5%",
                        bottom: "80px",
                        width: "95%",
                        margin: "0",
                        alignItems: "center",
                        justifyContent: "space-between",
                        zIndex: "100",
                        "& .MuiButtonBase-root": {
                          width: "48.5%",
                          padding: "4px",
                          "&:only-child": {
                            width: "100%",
                          },
                          "& .MuiButton-label": {
                            fontSize: "12px",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },

            "& .MuiTypography-root": {
              fontWeight: "bold",
            },

            "& .MuiTable-root": {
              "& .MuiButtonBase-root": {
                display: "flex",
                minWidth: "90px",
                padding: "6px 4px 4px",
                alignItems: "center",
                justifyContent: "center",

                "& .MuiButton-label": {
                  fontSize: "14px",
                },
              },
              "& .MuiTableCell-body": {
                height: "48px",
                padding: "8px",

                "@media (max-width: 767px)": {
                  "& .MuiButton-label": {
                    fontSize: "12px",
                  },
                },

                "& img": {
                  width: "30px",
                  height: "30px",
                  overflow: "hidden",
                  objectFit: "cover",
                },

                "& .MuiBox-root": {
                  "& .MuiAvatar-circle": {
                    marginRight: "6px",
                  },
                },

                "&.table-image": {
                  "& video": {
                    width: "100%",
                    maxWidth: "80px",
                  },
                },
              },
              "& .MuiTableCell-root": {
                padding: "8px",
              },
            },

            "& .MuiPagination-root": {
              width: "100%",
              marginTop: "20px",

              "& .MuiPagination-ul": {
                width: "100%",
                justifyContent: "center",

                "& .MuiPaginationItem-root": {
                  display: "flex",
                  width: "40px",
                  height: "40px",
                  padding: "0",
                  margin: "0",
                  fontSize: "14px",
                  borderRadius: "0",
                  alignItems: "center",
                  justifyContent: "center",
                },
              },
            },

            "& .MuiToolbar-gutters": {
              width: "100%",
              padding: "0",
              justifyContent: "space-between",

              "& .MuiSelect-select": {
                display: "flex",
                width: "115px",
                height: "38px",
                padding: "8px 30px 8px 20px",
                fontSize: "12px",
                background: "#EEE",
                borderColor: "#333",
                boxSizing: "border-box",
                alignItems: "center",
                "@media (max-width: 767px)": {
                  height: "32px",
                  padding: "6px 30px 6px 20px",
                },
              },
              "& .MuiSelect-icon": {
                right: "10px",
                color: "#333",
              },

              "& .MuiTextField-root": {
                width: "calc(100% - 125px)",
                height: "38px",
                padding: "0 15px",
                background: "#EEE",
                border: "none",
                borderRadius: "40px",
                boxSizing: "border-box",
                "@media (max-width: 767px)": {
                  height: "32px",
                },
                "& input": {
                  fontSize: "14px",
                  boxSizing: "border-box",
                  "@media (max-width: 767px)": {
                    height: "32px",
                  },
                },
                "& .MuiInput-underline": {
                  "&::before": {
                    content: "none",
                  },
                  "&::after": {
                    borderBottom: "transparent",
                  },
                },
              },
            },
          },
        },

        // Tiktok Icon
        "& .MuiListItem-gutters": {
          "& svg[data-icon='tiktok']": {
            width: "20px",
            marginLeft: "3px",
            height: "20px",

            "& path": {
              fill: "#7d7d7d",
            },
          },
        },

        "& .carousel.carousel-slider": {
          // paddingBottom: "15px",
          marginBottom: "10px",
          overflow: "initial",

          "& .slider-wrapper .slider": {
            alignItems: "center",
          },
          "& .control-arrow": {
            zIndex: 10,
          },
          "& .control-prev.control-arrow": {
            left: "-24px",
            "&:before": {
              borderRightColor: "#000",
            },
            "@media (max-width: 767px)": {
              left: "-21px",
            },
          },
          "& .control-next.control-arrow": {
            right: "-24px",
            "&:before": {
              borderLeftColor: "#000",
            },
            "@media (max-width: 767px)": {
              right: "-21px",
            },
          },

          "& .control-dots": {
            display: "flex",
            bottom: "-26px",
            height: "30px",
            margin: "0",
            alignItems: "center",
            justifyContent: "center",

            "& .dot": {
              position: "relative",
              margin: "0 5px",
              background: "#BBB",
              boxShadow: "none",
              // filter: "drop-shadow(0px 0px 1px rgba(0,0,0,0.5))",
              opacity: "0.8",

              "&.selected::after": {
                content: "''",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                width: "9px",
                height: "9px",
                background: "#5A2DDA",
                borderRadius: "50%",
                boxSizing: "border-box",
              },
            },
          },
        },
        "& .gallery-box": {
          position: "relative",
          height: "16vw",
          maxHeight: "247px",
          minHeight: "200px",
          background: "#fff",
          overflow: "hidden",
          "@media (max-width: 600px)": {
            height: "52vw",
            maxHeight: "180px",
          },
        },
        "& .MuiCardMedia-root": {
          width: "100%",
          height: "100%",
          transition: "0.3s",
        },

        "& .radioBtn": {
          "& .MuiFormControlLabel-root": {
            "&:not(:last-child)": {
              marginBottom: "16px",
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        margin: "0",
      },
    },
    MuiButtonBase: {
      root: {
        "&.MuiButtonBase-root": {
          "&.MuiRadio-root": {
            padding: "0",
            marginRight: "5px",
            "& .MuiSvgIcon-root": {
              width: "18px",
            },
            "& .MuiFormControlLabel-label": {
              fontSize: "14px",
            },
          },
          "&.MuiCheckbox-root": {
            padding: "0",
            marginRight: "5px",
          },
        },
        "&.Mui-checked": {
          "& .MuiSvgIcon-root": {
            color: "#000",
          },
        },
      },
    },
    MuiListItem: {
      root: {
        "& .MuiFormGroup-root": {
          "& .MuiFormControlLabel-root": {
            margin: "5px 0 0",
          },
        },
      },
    },
  },
})
