export const BANK_NAME_LOADED = "BANK_NAME_LOADED"
export const BANK_NAME_ERROR = "BANK_NAME_ERROR"
export const BRANCH_NAME_LOADED = "BRANCH_NAME_LOADED"
export const BRANCH_NAME_ERROR = "BRANCH_NAME_ERROR"

export const AVAILABLE_PAYMENT_METHODS_LOADED =
  "AVAILABLE_PAYMENT_METHODS_LOADED"
export const AVAILABLE_PAYMENT_METHODS_ERROR = "AVAILABLE_PAYMENT_METHODS_ERROR"

export const CATEGORY_DETAIL_LOADED = "CATEGORY_DETAIL_LOADED"
export const CATEGORY_DETAIL_CLEAR = "CATEGORY_DETAIL_CLEAR"
export const CATEGORY_DETAIL_ERROR = "CATEGORY_DETAIL_ERROR"

export const CATEGORY_LIST_LOADED = "CATEGORY_LIST_LOADED"
export const CATEGORY_LIST_ERROR = "CATEGORY_LIST_ERROR"
